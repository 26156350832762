import { Link, graphql, useStaticQuery } from "gatsby";
import { MDBCard, MDBCardBody, MDBCollapse, MDBIcon, MDBNavbarToggler } from 'mdbreact';
import React, {useState} from 'react';

import styled from 'styled-components';

const MDBCardCompMenu = styled(MDBCard)`
    width:100%;
    margin: 0px;
    padding:0px;
    position: relative;
    left: 50%;
    top: 14px!important;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
`

const MDBCardCompMobile = styled(MDBCard)`
    width:100%;
    margin:0px auto;
    padding:0px auto;
    text-align: center;
    position: fixed;
    top: inherit!important;
    bottom: 70px;
    left: 50%;
    top: 14px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 100;
    .navbar-toggler {
        outline: none;
        padding:0px!important;
        color: #575549;
        span {
            width:1em;
            height:1em;
        }
    }
`
const MDBCardBodyComp = styled(MDBCardBody)`
    text-align: left;
    @media only screen and (max-width: 767px) {
        padding-top: 0px;
    }
    ul{
        width:100%;
        margin: 0px;
        padding: 0px;
        list-style-position: outside;
        list-style: none;
        li {
            width:100%;
            background: none;
            border-bottom: 1px #ccc solid;
            padding: 0em 0.1em;
            margin: 0 auto;
            display: inline-block;
            a{
                color: #575549;
                font-size: 0.8em;
            }
            li {
                padding: 0em 0.4em;
                border-bottom: none;
            }
        }
    }
`

const Leftmenu = (props) => {

    const data = useStaticQuery(graphql`{
        allMenuLinkContentMenuLinkContent(sort: {fields: [weight], order: ASC}, filter: {enabled: {eq: true}}) {
            edges {
                node {
                    drupal_id
                    title
                    link {
                        uri
                        options {
                            attributes {
                                target
                            }
                        }
                    }
                    drupal_parent_menu_item
                    menu_name
                    weight
                    enabled
                }
            }
            totalCount
        }
    }`)


    const [collapseID, setCollapseID] = useState(false)

    const toggleSingleCollapse = collapseId => {
        setCollapseID(!collapseId)
    }

    let menuData = data.allMenuLinkContentMenuLinkContent.edges
    menuData = menuData.filter(item => {return item.node.menu_name.trim() ===  props.menuName.trim()});

    let menuArray = []
    
    menuData.map((itemNode) => {
        let item = itemNode.node
        let drupalIdValue = item.drupal_id
        let refidValue = item.drupal_id
        let titleValue = item.title.trim()

        let linkValue = item.link.uri.replace('internal:', '')
        let targetBlank = false
        if(item.link.options.attributes) {
            if (item.link.options.attributes.target === `_blank`) {
                targetBlank = true
            }
        }
        let linkParentValue = linkValue
        let positionValue = `parent`
        
        if(item.drupal_parent_menu_item != null) {
            refidValue = item.drupal_parent_menu_item.replace('menu_link_content:', '')
            positionValue = `children`
            menuData.forEach((idNode) => {
                if(refidValue === idNode.node.drupal_id) {
                    linkParentValue = idNode.node.link.uri.replace('internal:', '');
                }
            });
        }

        menuArray.push({
            drupalId : drupalIdValue,
            refid : refidValue,
            title : titleValue,
            link : linkValue,
            targetBlank : targetBlank,
            linkParent : linkParentValue,
            position: positionValue,
        })
    })

    let linkParentBD = menuArray.filter(item => {
        return item.link.includes(`/board-directors`)
    });
    if (linkParentBD[0]) {linkParentBD = linkParentBD[0].linkParent}

    let linksArrayValue = []
    menuArray.forEach((element) => {
        if (element.position === `children`) {
            linksArrayValue = []
            menuArray.forEach((keepParent) => {
                menuArray.forEach((subElement) => {
                    if(keepParent.linkParent === subElement.linkParent &&
                        subElement.link.includes(linkParentBD)) {
                        linksArrayValue.push(subElement.link.toLowerCase())
                    }
                });
                element[`linksArray`] =  linksArrayValue
            });
        }
    });

    let renderResultValue = (
        <ul>
            {menuArray.map((menuItem, key) => {
                if (menuItem.position === `parent`) {
                    return (
                        <li key={key}>
                            {menuItem.targetBlank ?
                            <a 
                                target="_blank"
                                href={`${menuItem.link.toLowerCase()}`}>{menuItem.title}</a>:
                            <a href={`${menuItem.link.toLowerCase()}`}>{menuItem.title}</a>}
                            <div><ul>
                                {menuArray.map((menuSubItem, subKey) => {
                                    if (menuSubItem.position === `children`) {
                                        if (menuItem.linkParent === menuSubItem.linkParent) {
                                            if (menuSubItem.linkParent.includes(linkParentBD)) {
                                                    return (
                                                        <li key={subKey}>
                                                            {menuSubItem.targetBlank ?
                                                            <a 
                                                                target="_blank"
                                                                href={`${menuSubItem.link.toLowerCase()}`}>{menuSubItem.title}</a>:
                                                            <a href={`${menuSubItem.link.toLowerCase()}`}>{menuSubItem.title}</a>}
                                                        </li>
                                                    )
                                            }
                                        }
                                    }
                                })}
                            </ul></div>
                        </li>
                    )
                }
            })}
        </ul>)

    return (
        <>
            {/* Desktop Menu */}
            <MDBCardCompMenu>
                <MDBCardBodyComp className="d-none d-md-inline"> 
                    {renderResultValue}
                </MDBCardBodyComp>
            </MDBCardCompMenu>

            {/* Mobile Menu */}
            <MDBCardCompMobile className="d-inline d-md-none">
                <MDBNavbarToggler id="mobilemenu" title="mobilemenu" aria-label="mobilemenu" aria-labelledby="mobilemenu" onClick={()=> toggleSingleCollapse(collapseID)}>
                    <MDBCollapse isOpen={collapseID}> 
                        <MDBIcon icon="fas fa-chevron-down" size="2x" />
                    </MDBCollapse>
                    <MDBCollapse isOpen={!collapseID}>
                        <MDBIcon icon="fas fa-chevron-up" size="2x" />
                    </MDBCollapse>
                </MDBNavbarToggler>
                <MDBCollapse isOpen={collapseID} navbar>
                    <MDBCardBodyComp>
                        {renderResultValue}
                    </MDBCardBodyComp>
                </MDBCollapse>
            </MDBCardCompMobile>
        </>
    );
}

export default Leftmenu;