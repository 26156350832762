import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../../../components/menus/boardbcmenu';
import DirectorPage from '../../../components/boardofdirectors/directorpage';
import Layout from '../../../components/layout';
import React from 'react';
import SEO from '../../../components/seo';
import SideNavBar from '../../../components/menus/boardnavbarmenu';
import { graphql } from 'gatsby';

let gmdv = require(`../../../ghc_config`);

const Detail = ({ data, location }) => {

  //  Menu relation
  let menuName = gmdv.getMenuid(`/investor-relations/corporate-governance/board-directors`)

  //  Page
  const page = data.nodeBoardOfDirectors
  const director = data.allParagraphBoardOfDirectors.edges[0].node
  let dirImage = '/images/avatars/noimage.png'
  if (director.relationships.field_member_photo != null) {
    dirImage = director.relationships.field_member_photo.localFile.childImageSharp.director_photo.src
  }

  let backgroundImage = ''
  if (page.relationships.field_image_banner != null) {
    backgroundImage = page.relationships.field_image_banner.localFile.childImageSharp.boardofdirectors.src
    backgroundImage = encodeURI(backgroundImage)
  }

  return (
    <Layout>
      <SEO 
        title={`${page.title} - ${director.field_title}`}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar menuName={menuName} location={location}/>
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${backgroundImage})`}}>
                {page.title}
              </h1>
              <BreadcrumbMenu title={director.field_title}/>
              <MDBRow center>
                <DirectorPage
                  title={director.field_title}
                  sub_title={director.field_sub_title}
                  description={director.field_description}
                  image={dirImage}
                />
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
query ($id: String!) {
  # Board Of Directors Home 
  nodeBoardOfDirectors(id: {eq: "b1e091d6-aabf-508c-8f7b-8a4b187871bc"}) {
    id
    title
    path {
      alias
    }
    relationships {
      field_image_banner {
        localFile {
          childImageSharp {
            boardofdirectors: original {
              src
            }
          }
        }
      }
    }
  }
  # Directors
  allParagraphBoardOfDirectors(filter: {
    id: {eq: $id},
    relationships: {node__board_of_directors: {elemMatch: {id: {eq: "b1e091d6-aabf-508c-8f7b-8a4b187871bc"}}}}}) 
  {
    edges {
      node {
        id
        field_title
        field_sub_title
        field_sortalias
        field_description {
          value
        }
        relationships {
          field_member_photo {
            localFile {
              childImageSharp {
                director_photo: resize(width: 132, height: 190) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }

  # Menu relation
  allMenuLinkContentMenuLinkContent {
    edges {
      node {
        title
        link {
          uri
        }
        menu_name
      }
    }
  }
}
`